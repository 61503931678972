import Dropzone from "react-dropzone";
import { notificationFail } from "store/slices/notificationSlice";
import { useAppDispatch } from "store/store";

function Index({
  children,
  multipleUpload,
  acceptedFiles,
  onDrop,
  minWidth,
  disabled,
  minHeight,
  maxSize,
  maxFiles,
  isFileTypeError,
}: any) {
  const style: any = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 30px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#b9b9b9",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#555555",
    outline: "none",
    transition: "border .24s ease-in-out",
    minHeight: minHeight || "100px",
    minWidth: minWidth || "200px",
    margin: "auto",
    textAlign: "center",
    fontSize: "14px",
    fontFamily: "roboto-regular",
    justifyContent: "center",
    cursor: "pointer",
    maxSize: maxSize || "",
    maxFiles: maxFiles || "",
  };
  const dispatch = useAppDispatch();
  return (
    <Dropzone
      multiple={multipleUpload}
      maxSize={maxSize}
      maxFiles={maxFiles}
      accept={acceptedFiles}
      onDrop={(acceptedFiles, fileRejections) => {
        onDrop(acceptedFiles);
        if ((maxSize || maxFiles) && fileRejections && fileRejections.length > 0) {
          for (let i = 0; i <= fileRejections.length; i++) {
            const singleObj = fileRejections[i];
            if (
              isFileTypeError &&
              singleObj?.errors &&
              singleObj?.errors?.filter((val: any) => val.code === "file-invalid-type")?.length
            ) {
              dispatch(notificationFail("Selected file type is not supported"));
            }
            if (
              singleObj?.errors &&
              singleObj?.errors?.filter((val: any) => val.code === "file-too-large")?.length > 0
            ) {
              const fileSizeMB = maxSize / 1024 ** 2;

              console.log(Math.floor(fileSizeMB));
              const errorMsg = `File size must be ${fileSizeMB} MB.Please make it smaller or Email at support@solasalonstudios.com.`;
              dispatch(notificationFail(errorMsg));
              break;
            }
            if (
              singleObj?.errors &&
              singleObj?.errors?.filter((val: any) => val.code === "too-many-files")?.length > 0
            ) {
              const errorMsg = `You can upload maximum ${maxFiles} number of file.`;
              dispatch(notificationFail(errorMsg));
              break;
            }
          }
        }
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            style,
            onClick: disabled ? (event: any) => event.stopPropagation() : null,
          })}
          className="pointer"
        >
          <input {...getInputProps()} />
          {children}
        </div>
      )}
    </Dropzone>
  );
}
export default Index;
