import React, { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ImagePreview from "layouts/pages/ImagePreview";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import SortableList from "components/CustomGalleryLocation";
import SortableListDrag from "components/CustomGallery";
import MDButton from "components/MDButton";
import { getLocation } from "store/thunk/locationThunk";
import { convertImageToWebp } from "helper/services";
import { WebsiteAttr, ImagesArray } from "layouts/pages/locations/location-details";
// import MDButton from "components/MDButton";

function Images(props: WebsiteAttr): JSX.Element {
  const {
    locationDetail,
    setLocationDetail,
    onSortEnd,
    imagesArray,
    setImagesArray,
    getAccessTypePermission,
    onSave,
    setNewImages,
  } = props;
  const dispatch = useAppDispatch();
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [isEditAltText, setIsEditAltText] = useState<any>(null);
  const [tempAltText, setTempAltText] = useState("");
  const [onRemoveImg, setOnRemoveImg] = useState("");
  const [isOpenReorderPopup, setIsOpenReorderPopup] = useState(false);

  useEffect(() => {
    if (isOpenReorderPopup) {
      document.body.classList.add("img-sort-modal-open");
    } else {
      document.body.classList.remove("img-sort-modal-open");
    }
  }, [isOpenReorderPopup]);

  useEffect(() => {
    if (onRemoveImg !== "") {
      onSave();
    }
  }, [onRemoveImg]);

  const onChangeImage = async (files: any, key: any) => {
    if (files[0]) {
      const deleteflag = `delete_image_${key}`;
      const file = await convertImageToWebp(files[0], "location", dispatch);
      const reader = new FileReader();
      reader.onload = function (e) {
        setLocationDetail({
          ...locationDetail,
          [`image_${key}_url`]: String(e.target.result),
          [`image_${key}`]: file,
          [`image_${key}_alt_text`]: `image_${key}`,
          [deleteflag]: null,
        });

        const tempArray = JSON.parse(JSON.stringify(imagesArray));
        const newImageObj = {
          index: key,
          id: key,
          url: String(e.target.result),
          alt_text: `image_${key}`,
          name: `image_${key}`,
        };
        tempArray.push(newImageObj);
        tempArray.map((item: any) => {
          if (!item.alt_text) {
            return {
              ...item,
              alt_text: item.name,
            };
          }
          return item;
        });
        setImagesArray(tempArray);
      };

      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, key: any, index?: any) => {
    e.stopPropagation();
    const tempArray: ImagesArray[] = JSON.parse(JSON.stringify(imagesArray));

    const findOne = tempArray.find((val: any) => val.index === index);
    if (index !== undefined) {
      const findIndex = tempArray.findIndex((val: any) => val.index === index);
      tempArray.splice(findIndex, 1);
    }
    if (key && findOne) {
      const deleteflag = `delete_image_${key}`;
      let imagesValues: any = {};
      tempArray.map((imgItem, i: number) => {
        imagesValues = {
          ...imagesValues,
          [`image_${i + 1}_url`]: imgItem.url,
          [`image_${i + 1}_alt_text`]: "",
          [`image_${i + 1}_name`]: imgItem.name,
          [`image_${i + 1}_content_type`]: imgItem.content_type,
          [`image_${i + 1}_file_name`]: imgItem.file_name,
          [`image_${i + 1}_file_size`]: imgItem.file_size,
          [`image_${i + 1}_updated_at`]: imgItem.updated_at,
        };
        return imagesValues;
      });
      const emptyArr = [...Array(20)];
      emptyArr.map((item, i) => {
        imagesValues = {
          ...imagesValues,
          [`image_${i + 1}_url`]: imagesValues[`image_${i + 1}_url`]
            ? imagesValues[`image_${i + 1}_url`]
            : null,
          [`image_${i + 1}_alt_text`]: "",
          [`image_${i + 1}_name`]: imagesValues[`image_${i + 1}_name`]
            ? imagesValues[`image_${i + 1}_name`]
            : null,
          [`image_${i + 1}_content_type`]: imagesValues[`image_${i + 1}_content_type`]
            ? imagesValues[`image_${i + 1}_content_type`]
            : null,
          [`image_${i + 1}_file_name`]: imagesValues[`image_${i + 1}_file_name`]
            ? imagesValues[`image_${i + 1}_file_name`]
            : null,
          [`image_${i + 1}_file_size`]: imagesValues[`image_${i + 1}_file_size`]
            ? imagesValues[`image_${i + 1}_file_size`]
            : null,
          [`image_${i + 1}_updated_at`]: imagesValues[`image_${i + 1}_updated_at`]
            ? imagesValues[`image_${i + 1}_updated_at`]
            : null,
        };
        return imagesValues;
      });
      const reOrderedImages = tempArray.map((item, index: number) => ({
        ...item,
        id: index + 1,
        index: index + 1,
        alt_text: item.name,
      }));

      setImagesArray(reOrderedImages);
      let newData = locationDetail;
      for (let i = 1; i <= 20; i++) {
        newData = {
          ...newData,
          [`image_${i}_url`]: null,
          [`image_${i}_alt_text`]: null,
          [`image_${i}_name`]: null,
          [`image_${i}_content_type`]: null,
          [`image_${i}_file_name`]: null,
          [`image_${i}_file_size`]: null,
          [`image_${i}_updated_at`]: null,
        };
      }
      setLocationDetail({
        ...newData,
        ...imagesValues,
        [deleteflag]: 1,
      });
      setNewImages({
        ...imagesValues,
      });
    }

    setOnRemoveImg(key);
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const showAddMoreButton = () => {
    let indexValue = 0;
    for (let index = 1; index <= 20; index++) {
      if (!locationDetail?.[`image_${index}_url`]) {
        indexValue = index;
        break;
      }
    }
    return indexValue;
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const onSaveAltText = () => {
    setLocationDetail({
      ...locationDetail,
      [`image_${isEditAltText}_alt_text`]: tempAltText,
    });

    const tempArray = JSON.parse(JSON.stringify(imagesArray));
    const index = tempArray.findIndex((x: any) => x.index === isEditAltText);
    tempArray[index].alt_text = tempAltText;

    setImagesArray(tempArray);
    setIsEditAltText(null);
    setTempAltText("");
  };

  const onOpenEditModal = async (e: any, key: any) => {
    e.stopPropagation();
    setIsEditAltText(key);
    setTempAltText(locationDetail?.[`image_${key}_alt_text`] || "");
  };

  const onChangeField = (e: any) => {
    setTempAltText(e.target.value);
  };

  const closeEditAltPopup = () => {
    setIsEditAltText(null);
    setTempAltText("");
  };

  const deleteDialogProps = {
    open: isEditAltText,
    onClose: () => closeEditAltPopup(),
    onSave: () => onSaveAltText(),
    title: `Edit Image ${isEditAltText} Alt Text`,
    size: "sm",
    saveBtn: true,
    deleteBtn: false,
    saveTbtText: "Save",
    closeBtnText: "Close",
  };

  const closeSortPopup = () => {
    dispatch(getLocation({ id: locationDetail?.id, cmsLocation: true }));
    setIsOpenReorderPopup(false);
  };

  const dialogProps = {
    open: isOpenReorderPopup,
    onClose: () => closeSortPopup(),
    onSave: () => {},
    title: `Sort images`,
    size: "lg",
    saveBtn: false,
  };

  return (
    <Card id="images" sx={{ overflow: "visible" }}>
      <MDBox
        p={3}
        pb={0}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <MDTypography variant="h5">Images</MDTypography>
        {imagesArray?.length > 0 &&
          !imagesArray.filter((val: any) => val?.url.startsWith("data:image/"))?.length && (
            <MDButton
              onClick={() => setIsOpenReorderPopup(true)}
              variant="text"
              color="dark"
              size="small"
              sx={{ padding: 0, fontSize: "14px", color: "#227bea", textTransform: "capitalize" }}
              disabled={getAccessTypePermission("location_website", "edit_access")}
            >
              Sort Images
            </MDButton>
          )}
      </MDBox>
      <MDBox component="form">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <MDBox>
              <SortableList
                previewImage={previewImage}
                showAddMoreButton={showAddMoreButton}
                onChangeImage={onChangeImage}
                onImageRemove={onImageRemove}
                onOpenEditModal={onOpenEditModal}
                axis="xy"
                pressDelay={90}
                items={imagesArray}
                onSortEnd={onSortEnd}
                disableDrag
                disabled={getAccessTypePermission("location_website", "edit_access")}
                showEditBtn={!getAccessTypePermission("location_website", "edit_access")}
                showDeleteBtn={!getAccessTypePermission("location_website", "edit_access")}
              />
              {isPreview && (
                <ImagePreview
                  imageData={imageToPreview}
                  open={isPreview}
                  onCloseImage={() => {
                    closeImagePreview();
                  }}
                />
              )}
              {isEditAltText && (
                <MDDialog {...deleteDialogProps}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDInput
                      label={`Image ${isEditAltText} Alt Text`}
                      variant="standard"
                      fullWidth
                      sx={{ margin: "5px 0px" }}
                      placeholder={`Image ${isEditAltText} Alt Text`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e)}
                      value={tempAltText || ""}
                      disabled={getAccessTypePermission("location_website", "edit_access")}
                    />
                  </Grid>
                </MDDialog>
              )}
            </MDBox>
          </Grid>
        </Grid>
        <MDDialog {...dialogProps}>
          <MDBox pb={2}>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} sm={12} md={12}>
                <SortableListDrag
                  previewImage={previewImage}
                  showAddMoreButton={false}
                  onChangeImage={onChangeImage}
                  onImageRemove={onImageRemove}
                  onOpenEditModal={onOpenEditModal}
                  axis="xy"
                  pressDelay={90}
                  items={imagesArray}
                  onSortEnd={onSortEnd}
                  disabled={getAccessTypePermission("location_website", "edit_access")}
                  showEditBtn={false}
                  showDeleteBtn={false}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDDialog>
      </MDBox>
    </Card>
  );
}

export default Images;
