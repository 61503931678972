import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Icon, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { getContactInquiriesList, deleteContactInquiries } from "store/thunk/systemAdmin";
import {
  adminSuperAdmin,
  getAssociationLocations,
  getLocalDateTime,
  formattedPhone,
  setDefaultSelectedColumns,
  getUrlName,
} from "helper/services";
import { useAppDispatch, useAppSelector } from "store/store";
import Messages from "helper/messages";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import ViewDetails from "./viewDetails";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const [updateData, setUpdateData] = useState<any>(null);
  const { contactInquiriesList } = useAppSelector((state) => state.systemAdminSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [deleteContactPopup, setDeleteContactInquiries] = useState<any>(false);
  const [selectedItems, setSelectedItems] = useState([]);
  // const [selectedObj, setSelectedObj] = useState([]);
  const [contactInquiries, setContactInquiriesList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);
  // const defaultColumns = [
  //   "checkbox",
  //   "name",
  //   "email",
  //   "phone",
  //   "location",
  //   "i_would_like_to_be_contacted",
  //   "how_can_we_help_you",
  //   "action",
  // ];

  const navigate = useNavigate();

  const fetchIdRef = useRef(0);
  // Get columns
  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "90px",
        checked: true,
      },
      { Header: "Name", accessor: "name", export: true, disableCheck: true, checked: true },
      { Header: "Email", accessor: "email", export: true, checked: true },
      { Header: "Phone", accessor: "phone", export: true, checked: true },
      {
        Header: "Location",
        accessor: "location",
        export: true,
        associative_colums: "locations.name",
        checked: true,
      },
      {
        Header: "I Would Like To Be Contacted",
        accessor: "i_would_like_to_be_contacted",
        width: "100px",
        export: true,
        is_boolean: true,
        checked: true,
      },
      {
        Header: "How Can We Help You",
        accessor: "how_can_we_help_you",
        width: "200px",
        export: true,
        checked: true,
      },
      { Header: "Created At", accessor: "created_at", width: "200px", export: true, is_date: true },
      {
        Header: "Newsletter Subscription",
        accessor: "newsletter",
        width: "200px",
        export: true,
        is_boolean: true,
      },
      { Header: "Message", accessor: "message", width: "200px", export: true, is_date: false },
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        export: false,
        disableSortBy: true,
        checked: true,
      },
    ];
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (contactInquiriesList && contactInquiriesList?.request_tour_inquiries) {
      setContactInquiriesList(contactInquiriesList?.request_tour_inquiries);
      setPageCount(contactInquiriesList?.meta?.total_pages);
      setTotalCount(contactInquiriesList?.meta?.total_count);
    }
  }, [contactInquiriesList]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        // SD-3199
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.request_tour_inquiries.table_name,
            table_columns: latestRefValue.current && latestRefValue.current,
            table_sort: sortBy,
            table_filter: filterData,
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }

        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(
          getContactInquiriesList({ pageSize, pageIndex, search, sort, userData, filterData })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.request_tour_inquiries.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      const respFilter = resp?.payload?.table_filter || [];
      const respSorting = resp?.payload?.table_sort || [];
      const defaultDBexportData = resp?.payload?.default_export_data || [];
      const defaultDBColumn = resp?.payload?.table_columns || [];
      const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
      setCheckedColumns(tempdata);
      setDefaultExport(defaultDBexportData);
      setDefaultSorting(respSorting);
      setSortBy(respSorting);
      setDefaultFilteing(respFilter);
      if (resp?.payload) {
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const openDeleteModal = (data: any) => {
    setUpdateData(data);
    setDeleteContactInquiries(true);
  };

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };
  const closeDetailPopup = () => {
    setUpdateData("");
    setDetailsPopup(false);
  };
  const goToEditPage = (data: any) => {
    navigate(`/crm/contact-inquiries/${data?.name ? getUrlName(data?.name) : ""}`, {
      state: { prevPath: "/crm/contact-inquiries", id: data?.id },
    });
  };

  const goToAddPage = () => {
    navigate(`/crm/contact-inquiries/create-contact-inquiry`, {
      state: { prevPath: "/crm/contact-inquiries" },
    });
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Info"
        color="info"
        onClick={() => openDetailPopup(object)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      {adminSuperAdmin() && (
        <>
          <MDIconButton
            tooltipName="Edit"
            aria-label="Edit"
            color="info"
            onClick={() => goToEditPage(object)}
          >
            <Icon fontSize="small">edit</Icon>
          </MDIconButton>
          <MDIconButton
            tooltipName="Delete"
            aria-label="Delete"
            color="error"
            onClick={() => openDeleteModal(object)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDIconButton>
        </>
      )}
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === contactInquiries.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = contactInquiries.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      // const unique = [...new Map(arr.map((item) => [item[key], item])).values()];
      setIsCheckAll(true);
    } else {
      allIds = contactInquiries.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
  };

  const clearCheckItem = () => {
    setSelectedItems([]);
    // setSelectedObj([]);
  };

  const getRows = () => {
    const data: any = contactInquiries.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id ? obj.id : "-",
      name: obj.name ? obj.name : "-",
      email: obj.email ? obj.email : "-",
      phone: obj.phone ? formattedPhone(obj.phone) : "-",
      location: obj?.location?.name ? obj?.location?.name : "-",
      i_would_like_to_be_contacted: obj?.i_would_like_to_be_contacted?.toString() || "-",
      how_can_we_help_you: obj.how_can_we_help_you ? obj.how_can_we_help_you : "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      newsletter: obj?.newsletter?.toString() || "-",
      message: obj?.message || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // useEffect(() => {
  //   const rowsArray = getRows();
  //   const obj = rowsArray.filter((val: any) => selectedItems.includes(val.id.toString()));
  //   const data = selectedObj.filter((val: any) => selectedItems.includes(val.id.toString()));
  //   const unique = [...new Map([...data, ...obj].map((item: any) => [item.id, item])).values()];
  //   setSelectedObj([...unique]);
  // }, [selectedItems]);

  const onCloseDeleteModal = () => {
    setDeleteContactInquiries(false);
    setUpdateData("");
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (updateData?.id) {
      const DelReq = {
        contactInquiriesData: updateData,
        onCloseDeleteModal,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteContactInquiries(DelReq));
    }
  };

  const deleteDialogProps = {
    open: deleteContactPopup,
    onClose: () => onCloseDeleteModal(),
    onSave: () => onDelete(),
    title: "Delete Contact Inquiries",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Contact Inquiries</p>
            {adminSuperAdmin() && (
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={goToAddPage}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
              </MDButton>
            )}
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                defaultDisplayColumns={displayColumns}
                setCheckedColumns={setCheckedColumns}
                table={tableData}
                canSearch
                searchText="Search Contact Inquiries..."
                selectedItems={selectedItems}
                showExportSelectedBtn
                showExportAllBtn
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                exportFields={exportData.request_tour_inquiries.export_fields}
                exportTable={exportData.request_tour_inquiries.table_name}
                exportType={exportData.request_tour_inquiries.export_type}
                joinTables={exportData.request_tour_inquiries.join_tables}
                advanceFilter
                manualSearch
                manualSort
                locationsIds={!adminSuperAdmin() && getAssociationLocations()}
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                checkedColumns={checkedColumns}
                // selectedObj={selectedObj}
                clearCheckItem={clearCheckItem}
              />
            )}
          {detailsPopup && (
            <ViewDetails
              open={detailsPopup}
              updateData={updateData}
              onClose={() => closeDetailPopup()}
            />
          )}
          {deleteContactPopup && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
